import React from "react"
import { Link, graphql } from "gatsby"

import { PageLayout } from "../components/PageLayout"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <PageLayout>
        {/* <Layout location={location} title={siteTitle}> */}
        <SEO
          title="All posts"
          ogImage={data?.socialImage?.childImageSharp?.fixed.src}
          description="Coaching professionals to look great, get stronger & play harder"
          url={location.origin + location.pathname}
        />
        {/* <Bio /> */}
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the directory you specified
          for the "gatsby-source-filesystem" plugin in gatsby-config.js).
        </p>
        {/* </Layout> */}
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <Layout location={location} title="All Blog Posts">
        {/* <SEO title="All posts" ogImage={data?.socialImage?.childImageSharp?.fixed.src} /> */}
        <SEO
          title="All posts"
          ogImage={data?.socialImage?.childImageSharp?.fixed.src}
          description="Coaching professionals to look great, get stronger & play harder"
          url={location.origin + location.pathname}
        />
        {/* <Bio /> */}
        <ol style={{ listStyle: `none` }}>
          {posts.map((post) => {
            const title = post.frontmatter.title || post.fields.slug

            return (
              <li key={post.fields.slug}>
                <article className="post-list-item" itemScope itemType="http://schema.org/Article">
                  <header>
                    <h2 style={{ fontWeight: "600" }}>
                      <Link to={"/blog" + post.fields.slug} itemProp="url">
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h2>
                    <small>{post.frontmatter.date}</small>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                      }}
                      itemProp="description"
                    />
                  </section>
                </article>
              </li>
            )
          })}
        </ol>
      </Layout>
    </PageLayout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
    socialImage: file(
      absolutePath: { regex: "/mitch-alex-bunker-wall-logo-social-1200x628.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 778, height: 778, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
